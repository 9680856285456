var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"unread-group button-border-radius bg-body-primary"},[_c('Title',[_vm._v(_vm._s(_vm.$t('company.circle_new_arrival')))]),_c('div',{staticClass:"border-bottom-left-right-radius"},[_vm._m(0),(!_vm.groupCompanyUnread.length)?_c('div',{staticClass:"text-center text-secondary py-6"},[_vm._v(" "+_vm._s(_vm.$t("common.message.no_unread"))+" ")]):_vm._e(),_vm._l((_vm.groupCompanyUnread),function(group,index){return _c('div',{key:index,staticClass:"pb-8",class:{ 'border-top': index, 'd-none': !group.count_unread }},[_c('div',{staticClass:"p-2"},[_c('div',{staticClass:"d-flex align-item-center"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
                group.icon_image_small_path
                  ? group.icon_image_small_path
                  : _vm.noGroupAvatar
              ),expression:"\n                group.icon_image_small_path\n                  ? group.icon_image_small_path\n                  : noGroupAvatar\n              "}],staticClass:"w--8 w-xl--12 h--8 h-xl--12 img-cover rounded-pill me-2"}),_c('div',{staticClass:"flex-center"},[(group.status_company == 'stop' || group.status_company == 'leave')?_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(_vm.handleLongText(group.name).isLong),expression:"handleLongText(group.name).isLong",modifiers:{"hover":true}}],staticClass:"text-line-clamp-1 fs-14 fs-xl-16 fwb hover-o-75",attrs:{"title":group.name}},[_vm._v(" "+_vm._s(_vm.handleLongText(group.name).text)+" ")]):_c('router-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({
                  customClass: _vm.handleLongText(group.name).isLong
                    ? 'd-block'
                    : 'd-none',
                }),expression:"{\n                  customClass: handleLongText(group.name).isLong\n                    ? 'd-block'\n                    : 'd-none',\n                }",modifiers:{"hover":true}}],staticClass:"text-line-clamp-1 fs-14 fs-xl-16 fwb hover-o-75",attrs:{"to":{ name: 'group-toppage', params: { groupId: group.id } },"title":group.name}},[_vm._v(" "+_vm._s(_vm.handleLongText(group.name).text)+" ")])],1)]),_c('div',{staticClass:"d-none d-xl-block border-top-xl border-secondary mt-2 mb-2 ms-13 me-4"}),_c('div',{staticClass:"d-flex justify-content-between ps-8 ps-xl-12 me-2"},[_c('span',{staticClass:"ms-2 flex-center"},[_vm._v(" "+_vm._s(_vm.$t("common.message.unread_post", { count: group.count_unread }))+" ")]),_c('button',{staticClass:"btn btn-outline-secondary-deep btn-light mt-n1 mt-xl-0 me-1 me-xl-7 button-border-radius",on:{"click":function($event){return _vm.markReadAllGroupCompany(index)}}},[_vm._v(" "+_vm._s(_vm.$t("common.btn.btn_mark_as_read"))+" ")])])]),_c('div',{staticClass:"px-3"},[_c('div',{staticClass:"row ms-xl-10 me-xl-10 gy-5"},_vm._l((group.data),function(topicFile,childIndex){return _c('div',{key:childIndex,staticClass:"topic-file col-xl-4 col-md-6 col-xs-12"},[(topicFile.is_topic)?_c('ListTopic',{attrs:{"topics":[topicFile],"statusCompany":group.status_company,"disableCheckbox":true,"isGroupCompany":true,"isFromHome":true,"groupId":group.id,"headerImage":group.header_image},on:{"subtractReadCount":function($event){return _vm.markReadGroupCompany(index, childIndex)}}}):_c('SharedFolderList',{attrs:{"files":[topicFile],"statusCompany":group.status_company,"disableCheckbox":true,"forGroupTop":true,"isFromHome":true,"groupId":group.id,"headerImage":group.header_image,"isGroupCompany":true},on:{"subtractReadCount":function($event){return _vm.markReadGroupCompany(index, childIndex)}}})],1)}),0)]),(
            _vm.getPageGroupDataCompany(group.id).page < _vm.getPageGroupDataCompany(group.id).maxPage
          )?_c('div',{staticClass:"text-center py-4 border-secondary mx-xl-6"},[_c('button',{staticClass:"btn btn-outline-secondary-deep button-border-radius",on:{"click":function($event){return _vm.loadmoreGroupDataCompany(index)}}},[_vm._v(" "+_vm._s(_vm.$t("common.btn.btn_see_more"))+" ")])]):_vm._e()])}),(_vm.isLoading)?_c('div',{staticClass:"text-center py-4"},[_c('b-spinner',{attrs:{"label":"Spinning"}})],1):_vm._e(),(_vm.showLoadmore)?_c('div',{staticClass:"text-center py-4 border-top border-secondary"},[_c('button',{staticClass:"btn btn-outline-secondary-deep text-nowrap button-border-radius",on:{"click":_vm.loadmoreGroup}},[_vm._v(" "+_vm._s(_vm.$t("common.btn.btn_see_more"))+" ")])]):_vm._e()],2)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-2 px-xl-4 pt-1 pe-4"},[_c('div',{staticClass:"border d-none"})])}]

export { render, staticRenderFns }