var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cursor-pointer d-block h--80 shadow border-secondary button-border-radius hover-linear-gradient",staticStyle:{"overflow":"hidden !important"}},[(!_vm.topics.length && !_vm.startLoading)?_c('div',{staticClass:"text-center text-secondary pt-6"},[_vm._v(" "+_vm._s(_vm.$t("common.message.no_data"))+" ")]):_vm._e(),_vm._l((_vm.topics),function(topic,index){return _c('div',{key:topic.id},[(_vm.statusCompany == 'stop' || _vm.statusCompany == 'leave')?_c('div',[_c('div',{staticClass:"h--50"},[_c('img',{staticClass:"img-cover flex-fixed m-0",attrs:{"src":topic.path
              ? topic.path
              : topic.groups && topic.groups.length > 0
              ? topic.groups[0].header_image
              : _vm.headerImage
              ? _vm.headerImage
              : topic.file_common_topics &&
                topic.file_common_topics.length > 0
              ? topic.file_common_topics[0].path
              : _vm.headerDefault}})]),_c('div',{staticClass:"px-5 py-5"},[_c('div',{staticClass:"d-none d-xl-block",class:{ 'd-xl-none': !_vm.isFromHome }}),_c('div',{staticClass:"d-block"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"text-line-clamp-1 fwb"},[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(topic.title),expression:"topic.title",modifiers:{"hover":true}}]},[_vm._v(" "+_vm._s(_vm.truncateText(topic.title))+" ")])])])]),_c('div',{staticClass:"col-6"},[(!_vm.isDraft)?_c('div',{staticClass:"text-end"},[(!topic.is_read)?_c('button',{staticClass:"btn btn-outline-secondary-deep btn-light button-border-radius text-nowrap",on:{"click":[function($event){$event.stopPropagation();},function($event){return _vm.handleMarkRead(index)}]}},[_vm._v(" "+_vm._s(_vm.$t("common.btn.btn_mark_as_read"))+" ")]):_vm._e()]):_vm._e()])]),_c('label',{staticClass:"d-flex flex-column justify-content-between px-3 pt-",attrs:{"for":("checkbox-" + index)}},[_c('div',{staticClass:"row justify-content-between"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"d-flex gap-5 align-items-center"},[_c('div',{staticClass:"d-flex align-items-center"},[(!_vm.isFromHome)?_c('img',{staticClass:"rounded-pill img-cover flex-fixed h--7 w--7 h-xl--9 w-xl--9 me-1",attrs:{"src":topic.updater_avatar && !topic.allow_anonymous_comment
                          ? topic.updater_avatar
                          : _vm.noAvatar}}):_c('img',{staticClass:"rounded-pill img-cover flex-fixed h--7 w--7 h-xl--9 w-xl--9 me-1",attrs:{"src":topic.group_avatar
                          ? topic.group_avatar
                          : _vm.isHomeCommon
                          ? _vm.commonTopicAvatar
                          : _vm.noGroupAvatar}}),(topic.update_topic)?_c('i',{staticClass:"fas fa-ballot fs-24 fs-xl-30 px-1 color-icon-file"}):_c('i',{staticClass:"fas fa-comment-alt-lines fs-24 fs-xl-30 color-icon-file"})]),_c('div',{staticClass:"mt-3 ps-2"})])]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"d-flex align-items-center justify-content-xl-between flex-1 h-100 justify-content-sm-end"},[(!topic.allow_anonymous_comment)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
                      topic.status_company == 'stop'
                        ? '停止された企業様'
                        : topic.status_company == 'leave'
                        ? '退会された企業様'
                        : topic.name_company != ''
                        ? _vm.handleLongText(topic).text +
                          '@' +
                          topic.name_company
                        : _vm.handleLongText(topic).text
                    ),expression:"\n                      topic.status_company == 'stop'\n                        ? '停止された企業様'\n                        : topic.status_company == 'leave'\n                        ? '退会された企業様'\n                        : topic.name_company != ''\n                        ? handleLongText(topic).text +\n                          '@' +\n                          topic.name_company\n                        : handleLongText(topic).text\n                    ",modifiers:{"hover":true,"top":true}}],staticClass:"updater-name text-line-clamp-1 fs-14",class:{
                      'text-secondary-tint-less': topic.user_active_status,
                    }},[_vm._v(" "+_vm._s(topic.status_company == "stop" ? "停止された企業様" : topic.status_company == "leave" ? "退会された企業様" : topic.name_company != "" ? _vm.handleLongText(topic).text + "@" + topic.name_company : _vm.handleLongText(topic).text)+" ")]):_c('span',[_vm._v(_vm._s(_vm.$t("groups.new_feed.anonymous")))]),_c('span',{staticClass:"flex-fixed px-3 fs-14"},[_vm._v(" "+_vm._s(_vm.formatDateWithDays(topic.update_at))+" ")])])])]),(!_vm.isCommonTopic && !_vm.disableCheckbox)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(topic.checked),expression:"topic.checked"}],staticClass:"form-check-input mt-1 ms-xl-1",attrs:{"id":("checkbox-" + index),"type":"checkbox"},domProps:{"checked":Array.isArray(topic.checked)?_vm._i(topic.checked,null)>-1:(topic.checked)},on:{"change":[function($event){var $$a=topic.checked,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(topic, "checked", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(topic, "checked", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(topic, "checked", $$c)}},_vm.handleCheckItem]}}):_vm._e()])]),_c('div',{staticClass:"d-none d-xl-block border-top-xl border-secondary",class:{ 'd-xl-none': _vm.isFromHome }})])]):_c('div',{on:{"click":function($event){_vm.$router.push(_vm.jumpToTopicDetailPage(index, false))}}},[_c('div',{staticClass:"h--50"},[_c('img',{staticClass:"img-cover flex-fixed m-0",attrs:{"src":topic.path
              ? topic.path
              : topic.groups && topic.groups.length > 0
              ? topic.groups[0].header_image
              : _vm.headerImage
              ? _vm.headerImage
              : topic.file_common_topics &&
                topic.file_common_topics.length > 0
              ? topic.file_common_topics[0].path
              : _vm.headerDefault}})]),_c('div',{staticClass:"px-5 py-5"},[_c('div',{staticClass:"d-none d-xl-block",class:{ 'd-xl-none': !_vm.isFromHome }}),_c('div',{staticClass:"d-block"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"text-line-clamp-1 fwb"},[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(topic.title),expression:"topic.title",modifiers:{"hover":true}}]},[_vm._v(" "+_vm._s(_vm.truncateText(topic.title))+" ")])])])]),_c('div',{staticClass:"col-6"},[(!_vm.isDraft)?_c('div',{staticClass:"text-end"},[(!topic.is_read)?_c('button',{staticClass:"btn btn-outline-secondary-deep btn-light button-border-radius text-nowrap",on:{"click":[function($event){$event.stopPropagation();},function($event){return _vm.handleMarkRead(index)}]}},[_vm._v(" "+_vm._s(_vm.$t("common.btn.btn_mark_as_read"))+" ")]):_vm._e()]):_vm._e()])]),_c('label',{staticClass:"d-flex flex-column justify-content-between px-3 pt-",attrs:{"for":("checkbox-" + index)}},[_c('div',{staticClass:"row justify-content-between"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"d-flex gap-5 align-items-center"},[_c('div',{staticClass:"d-flex align-items-center"},[(!_vm.isFromHome)?_c('img',{staticClass:"rounded-pill img-cover flex-fixed h--7 w--7 h-xl--9 w-xl--9 me-1",attrs:{"src":topic.updater_avatar && !topic.allow_anonymous_comment
                          ? topic.updater_avatar
                          : _vm.noAvatar}}):_c('img',{staticClass:"rounded-pill img-cover flex-fixed h--7 w--7 h-xl--9 w-xl--9 me-1",attrs:{"src":topic.group_avatar
                          ? topic.group_avatar
                          : _vm.isHomeCommon
                          ? _vm.commonTopicAvatar
                          : _vm.noGroupAvatar}}),(topic.update_topic)?_c('i',{staticClass:"fas fa-ballot fs-24 fs-xl-30 px-1 color-icon-file"}):_c('i',{staticClass:"fas fa-comment-alt-lines text-primary fs-24 fs-xl-30 color-icon-file"})]),_c('div',{staticClass:"mt-3 ps-2"})])]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"d-flex align-items-center justify-content-xl-between flex-1 h-100 justify-content-sm-end"},[(!topic.allow_anonymous_comment)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
                      topic.status_company == 'stop'
                        ? '停止された企業様'
                        : topic.status_company == 'leave'
                        ? '退会された企業様'
                        : topic.name_company != ''
                        ? _vm.handleLongText(topic).text +
                          '@' +
                          topic.name_company
                        : _vm.handleLongText(topic).text
                    ),expression:"\n                      topic.status_company == 'stop'\n                        ? '停止された企業様'\n                        : topic.status_company == 'leave'\n                        ? '退会された企業様'\n                        : topic.name_company != ''\n                        ? handleLongText(topic).text +\n                          '@' +\n                          topic.name_company\n                        : handleLongText(topic).text\n                    ",modifiers:{"hover":true,"top":true}}],staticClass:"updater-name text-line-clamp-1 fs-14",class:{
                      'text-secondary-tint-less': topic.user_active_status,
                    }},[_vm._v(" "+_vm._s(topic.status_company == "stop" ? "停止された企業様" : topic.status_company == "leave" ? "退会された企業様" : topic.name_company != "" ? _vm.handleLongText(topic).text + "@" + topic.name_company : _vm.handleLongText(topic).text)+" ")]):_c('span',[_vm._v(_vm._s(_vm.$t("groups.new_feed.anonymous")))]),_c('span',{staticClass:"flex-fixed px-3 fs-14"},[_vm._v(" "+_vm._s(_vm.formatDateWithDays(topic.update_at))+" ")])])])]),(!_vm.isCommonTopic && !_vm.disableCheckbox)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(topic.checked),expression:"topic.checked"}],staticClass:"form-check-input mt-1 ms-xl-1",attrs:{"id":("checkbox-" + index),"type":"checkbox"},domProps:{"checked":Array.isArray(topic.checked)?_vm._i(topic.checked,null)>-1:(topic.checked)},on:{"change":[function($event){var $$a=topic.checked,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(topic, "checked", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(topic, "checked", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(topic, "checked", $$c)}},_vm.handleCheckItem]}}):_vm._e()])]),_c('div',{staticClass:"d-none d-xl-block border-top-xl border-secondary",class:{ 'd-xl-none': _vm.isFromHome }})])])])}),_c('ModalError',{attrs:{"id":'modal-error-list-topic',"errorMess":_vm.$t('common.message.uncatch_error')}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }